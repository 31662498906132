import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaReact, FaNodeJs, FaDatabase, FaCode } from 'react-icons/fa';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const AboutWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 7rem 2rem 5rem;
  animation: ${fadeIn} 0.6s ease-out;

  @media (max-width: 768px) {
    padding: 5rem 1rem 3rem;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: var(--primary);
  margin-bottom: 2rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 1.5rem;
  color: var(--accent);
  margin-bottom: 1.5rem;
  font-family: 'Poppins', sans-serif;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: var(--text);
`;

const SkillsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 3rem;
`;

const SkillItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`;

const SkillIcon = styled.div`
  font-size: 3rem;
  color: var(--primary);
  margin-bottom: 1rem;
`;

const SkillName = styled.span`
  font-size: 1.1rem;
  color: var(--text);
`;

function About() {
  return (
    <AboutWrapper>
      <Title>About Me</Title>
      <Subtitle>Passionate Full Stack Developer</Subtitle>
      <Paragraph>
        Hello! I'm Prasanga, a dedicated full stack developer with a passion for creating
        innovative and efficient web solutions. With a strong foundation in both front-end
        and back-end technologies, I strive to build seamless, user-friendly applications
        that make a difference.
      </Paragraph>
      <Paragraph>
        My journey in the world of programming began with a curiosity to understand how
        things work behind the scenes. This curiosity has driven me to continuously learn
        and adapt to new technologies, allowing me to stay at the forefront of web development.
      </Paragraph>
      <Paragraph>
        When I'm not coding, you can find me exploring new technologies, contributing to
        open-source projects, or sharing my knowledge through blog posts and mentoring.
        I believe in the power of community and collaboration to drive innovation in the
        tech industry.
      </Paragraph>
      <SkillsContainer>
        <SkillItem>
          <SkillIcon><FaReact /></SkillIcon>
          <SkillName>React</SkillName>
        </SkillItem>
        <SkillItem>
          <SkillIcon><FaNodeJs /></SkillIcon>
          <SkillName>Node.js</SkillName>
        </SkillItem>
        <SkillItem>
          <SkillIcon><FaDatabase /></SkillIcon>
          <SkillName>Databases</SkillName>
        </SkillItem>
        <SkillItem>
          <SkillIcon><FaCode /></SkillIcon>
          <SkillName>Full Stack</SkillName>
        </SkillItem>
      </SkillsContainer>
    </AboutWrapper>
  );
}

export default About;
