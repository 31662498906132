import React from 'react';
import styled, { keyframes } from 'styled-components';
import ProjectCard from '../components/ProjectCard';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const ProjectsWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 7rem 2rem 5rem; // Increased top padding

  @media (max-width: 768px) {
    padding: 5rem 1rem 3rem; // Adjusted for mobile
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: var(--primary);
  margin-bottom: 2rem;
  text-align: center;
  animation: ${fadeIn} 0.6s ease-out;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const projectsData = [
  {
    id: 1,
    title: "E-commerce Platform",
    description: "A full-stack e-commerce solution with React, Node.js, and MongoDB.",
    link: "https://github.com/yourusername/ecommerce-project",
    tags: ["React", "Node.js", "MongoDB"]
  },
  {
    id: 2,
    title: "Weather App",
    description: "Real-time weather application using OpenWeatherMap API and React.",
    link: "https://github.com/yourusername/weather-app",
    tags: ["React", "API Integration"]
  },
  {
    id: 3,
    title: "Task Management System",
    description: "A Kanban-style task management app with drag-and-drop functionality.",
    link: "https://github.com/yourusername/task-manager",
    tags: ["Vue.js", "Vuex", "Firebase"]
  },
  {
    id: 4,
    title: "Portfolio Website",
    description: "A responsive portfolio website showcasing my projects and skills.",
    link: "https://github.com/yourusername/portfolio",
    tags: ["React", "Styled Components"]
  }
];

function Projects() {
  return (
    <ProjectsWrapper>
      <Title>My Projects</Title>
      <ProjectGrid>
        {projectsData.map((project, index) => (
          <ProjectCard
            key={project.id}
            {...project}
            delay={index * 0.1}
          />
        ))}
      </ProjectGrid>
    </ProjectsWrapper>
  );
}

export default Projects;
