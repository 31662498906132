import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Card = styled.div`
  background-color: var(--card-bg);
  border-radius: 12px;
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: ${fadeIn} 0.6s ease-out forwards;
  animation-delay: ${props => props.delay}s;
  opacity: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    padding: 1.25rem;
  }
`;

const ProjectTitle = styled.h3`
  color: var(--accent);
  margin-bottom: 0.75rem;
  font-size: 1.5rem;
`;

const ProjectDescription = styled.p`
  margin-bottom: 1rem;
  color: var(--text);
  font-size: 1rem;
  line-height: 1.6;
  flex-grow: 1;
`;

const ProjectLink = styled.a`
  background-color: var(--primary);
  color: var(--background);
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  align-self: flex-start;

  &:hover {
    background-color: var(--accent);
    color: var(--text);
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.6rem 1.2rem;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const Tag = styled.span`
  background-color: rgba(163, 85, 201, 0.1);
  color: var(--primary);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
`;

function ProjectCard({ title, description, link, tags, delay }) {
  return (
    <Card delay={delay}>
      <ProjectTitle>{title}</ProjectTitle>
      <TagContainer>
        {tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagContainer>
      <ProjectDescription>{description}</ProjectDescription>
      <ProjectLink href={link} target="_blank" rel="noopener noreferrer">
        View Project
      </ProjectLink>
    </Card>
  );
}

export default ProjectCard;
