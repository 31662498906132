import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --primary: #a355c9;
    --secondary: #4CAF50;
    --background: #0F172A;
    --text: #E2E8F0;
    --accent: #F59E0B;
    --card-bg: rgba(255, 255, 255, 0.05);
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Inter', sans-serif;
    background: linear-gradient(135deg, var(--background) 0%, #1E293B 100%);
    color: var(--text);
    line-height: 1.6;
    min-height: 100vh;
  }

  a {
    color: var(--primary);
    text-decoration: none;
    transition: color 0.3s ease;
  }

  a:hover {
    color: var(--accent);
  }

  h1, h2, h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }

  @media (max-width: 768px) {
    .container {
      padding: 0 1rem;
    }
  }

  body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      radial-gradient(circle, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
    background-size: 20px 20px;
    z-index: -1;
    pointer-events: none;
  }

  body {
    background-attachment: fixed;
  }
`;

export default GlobalStyles;
